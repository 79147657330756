:root {
  --font-display: "Josefin Sans", sans-serif;
  --font-body: "Lora", serif;
  --font-mono: "Roboto Mono", monospace;
}

button {
  --btn-border-color: var(--c-border, #ccc);
}

:root {
  --c-lodge-brown: #98712a;
  --c-lodge-black: #4d4d4d;
  --c-lodge-gray: #8c8c8c;
  --c-lodge-pink: #f787df;
  --c-lodge-purple: #df4af2;
  --c-error: #d92626;
  --c-warning: #fc923b;
  --c-success: #4ac738;
  --c-info: #4268b3;
  --c-instagram: #843bb5;
  --c-facebook: #4268b3;
  --c-pinterest: #e60022;
  --c-black: #000;
  --c-white: #fff;
  --c-light1: #f7f7f7;
  --c-light2: #e0e0e0;
  --c-light3: #c7c7c7;
  --c-light4: #b0b0b0;
  --c-light5: #999;
  --c-dark5: #707070;
  --c-dark4: #595959;
  --c-dark3: #404040;
  --c-dark2: #292929;
  --c-dark1: #0f0f0f;
  --c-text-light: var(--c-light1, #f7f7f7);
  --c-text-dark: var(--c-dark1, #0f0f0f);
  --c-text: var(--c-text-dark);
  --c-link: var(--c-lodge-purple);
  --c-link-active: var(--c-lodge-pink);
  --c-border: var(--c-light3);
  --c-outline: var(--c-lodge-pink);
  --c-placeholder: #dedede;
}

.dark-mode {
  --c-text: var(--c-text-light);
  --c-link: var(--c-lodge-pink);
  --c-link-active: var(--c-lodge-purple);
}

.light-mode {
  --c-text: var(--c-text-dark);
  --c-link: var(--c-lodge-purple);
  --c-link-active: var(--c-lodge-pink);
}

:root {
  --shdw-1: 0px 1px 3px #1a1a1a1f;
  --shdw-2: 0px 6px 8px #1a1a1a14, 0px 0px 3px #1a1a1a1f, 0px 0px 1px #1a1a1a33;
  --shdw-3: 0px 8px 16px #1a1a1a14, 0px 2px 10px #1a1a1a24, 0px 0px 1px #1a1a1a33;
  --shdw-4: 0px 15px 30px #1a1a1a1f, 0px 5px 15px #1a1a1a2e, 0px 0px 1px #1a1a1a33;
  --shdw-focus: 0 0 0 2px #1a1a1a33;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  color: var(--c-text, #0f0f0f);
  will-change: background-color, color;
  margin: 0;
  transition-property: background-color, color;
  transition-duration: .3s, .2s;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

.btn, .btn--alt, button, [type="button"], [type="reset"], [type="submit"] {
  --color: var(--c-dark4, #aaa);
  --hover-color: var(--c-lodge-purple, purple);
  --border-width: 2px;
  --duration: .2s;
}

.dark-mode .btn, .dark-mode .btn--alt, .dark-mode button, .dark-mode [type="button"], .dark-mode [type="reset"], .dark-mode [type="submit"] {
  --color: var(--c-light1, #eee);
  --hover-color: var(--c-lodge-pink, pink);
}

.light-mode .btn, .light-mode .btn--alt, .light-mode button, .light-mode [type="button"], .light-mode [type="reset"], .light-mode [type="submit"] {
  --color: var(--c-dark2, #222);
  --hover-color: var(--c-lodge-purple, purple);
}

.btn, .btn--alt, button, [type="button"], [type="reset"], [type="submit"] {
  box-sizing: border-box;
  appearance: button;
  height: 1rem;
  max-width: 100%;
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: var(--border-width) solid var(--color);
  color: var(--color);
  cursor: pointer;
  vertical-align: bottom;
  transition-property: color, border-color, background-color;
  transition-duration: var(--duration);
  will-change: color, border-color;
  background-color: #0000;
  margin: 0;
  padding: 0 .5rem;
  font-family: inherit;
  font-size: 100%;
  font-weight: 400;
  line-height: 1rem;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.btn:hover, .btn:focus, .btn--alt:hover, .btn--alt:focus, button:hover, button:focus, [type="button"]:hover, [type="button"]:focus, [type="reset"]:hover, [type="reset"]:focus, [type="submit"]:hover, [type="submit"]:focus {
  color: var(--hover-color);
}

.btn:focus, .btn--alt:focus, button:focus, [type="button"]:focus, [type="reset"]:focus, [type="submit"]:focus {
  outline-style: solid;
  outline-color: var(--c-outline, blue);
}

form .btn, form .btn--alt, form button, form [type="button"], form [type="reset"], form [type="submit"] {
  height: 1.5rem;
}

[type="search"] + button {
  overflow: revert;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted var(--c-outline, blue);
}

a {
  color: var(--c-link);
  will-change: color;
  transition-property: color;
  transition-duration: .2s;
}

a:focus {
  outline: 2px solid var(--c-outline, blue);
}

a:hover, a:focus {
  color: var(--c-link-active);
}

a svg {
  height: .5rem;
  width: .5rem;
  fill: currentColor;
  will-change: fill;
  transition-property: fill;
  transition-duration: .2s;
  position: relative;
  top: 2px;
}

.link, button.link {
  height: auto;
  line-height: initial;
  color: var(--c-link);
  text-transform: none;
  background-color: #0000;
  border: 0;
  padding: 0;
  font-family: inherit;
  text-decoration: underline;
  display: inline;
}

.link:before, .link:after, button.link:before, button.link:after {
  display: none;
}

.link:focus, button.link:focus {
  outline: 2px solid var(--c-outline, blue);
}

.link:hover, .link:focus, button.link:hover, button.link:focus {
  color: var(--c-link-active);
}

.link:hover svg, .link:focus svg, button.link:hover svg, button.link:focus svg {
  fill: var(--c-link-active);
  transform: none;
}

.link svg, button.link svg {
  fill: currentColor;
  opacity: 1;
}

template, [hidden] {
  display: none;
}

img, picture, video, canvas {
  display: block;
}

img, picture, video, canvas, svg {
  max-width: 100%;
}

img {
  border-style: none;
}

figure {
  margin: 0;
}

audio {
  height: 1rem;
  display: block;
}

video {
  min-height: 5rem;
}

hr {
  box-sizing: border-box;
  height: 0;
  border: 0;
  border: var(--c-border, #ccc);
  border-top-style: solid;
  border-top-width: 1px;
  margin-top: calc(.5rem - 1px);
  margin-bottom: .5rem;
  overflow: visible;
}

details {
  display: block;
}

summary {
  cursor: pointer;
  display: list-item;
}

summary:focus {
  outline: 2px solid var(--c-outline);
}

html {
  font-family: var(--font-body, sans-serif);
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-display, sans-serif);
}

pre, code, kbd, samp {
  font-family: var(--font-mono, monospace), var(--font-mono, monospace);
}

b, strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

body {
  text-rendering: optimizespeed;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, dl, dd, ul, ol {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, p, li, dt, dd {
  overflow-wrap: break-word;
  max-width: 50em;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

p code, samp, small, sub, sup, var {
  line-height: 1;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

h2 {
  margin-top: 2rem;
}

h3, h4, h5, h6 {
  margin-top: 1rem;
}

p {
  margin-top: .5rem;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, p:first-child {
  margin-top: 0;
}

input, optgroup, select, textarea {
  box-sizing: border-box;
  height: 1.5rem;
  margin: 0;
  padding-left: .5rem;
  padding-right: .5rem;
  font-family: inherit;
  font-size: 100%;
  line-height: 1rem;
}

input {
  overflow: visible;
}

select {
  text-transform: none;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

fieldset {
  border-width: 1px 0 0;
  padding: calc(.5rem - 1px) .5rem 1rem;
}

legend {
  padding: 0 .25rem 1px;
}

input[type="text"], input[type="number"], input[type="email"], input[type="tel"], input[type="url"], input[type="date"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="time"], input[type="password"], input[type="search"], input[list], textarea, select {
  width: 100%;
  backdrop-filter: blur(3px);
  color: var(--c-white);
  will-change: border-color, background-color, color;
  background-color: #ffffff4d;
  border: 1px solid #fffc;
  font-weight: bold;
  transition-property: border-color, background-color, color;
  transition-duration: .5s;
  display: block;
}

input[type="text"] svg, input[type="text"] path, input[type="number"] svg, input[type="number"] path, input[type="email"] svg, input[type="email"] path, input[type="tel"] svg, input[type="tel"] path, input[type="url"] svg, input[type="url"] path, input[type="date"] svg, input[type="date"] path, input[type="datetime-local"] svg, input[type="datetime-local"] path, input[type="month"] svg, input[type="month"] path, input[type="week"] svg, input[type="week"] path, input[type="time"] svg, input[type="time"] path, input[type="password"] svg, input[type="password"] path, input[type="search"] svg, input[type="search"] path, input[list] svg, input[list] path, textarea svg, textarea path, select svg, select path {
  fill: currentColor;
}

input[type="text"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="tel"]:focus, input[type="url"]:focus, input[type="date"]:focus, input[type="datetime-local"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[list]:focus, textarea:focus, select:focus {
  color: var(--c-dark1);
  outline: 2px solid var(--c-outline);
  outline-offset: 1px;
  background-color: #fffc;
  border-color: #fff;
}

input[type="text"]:focus::placeholder, input[type="number"]:focus::placeholder, input[type="email"]:focus::placeholder, input[type="tel"]:focus::placeholder, input[type="url"]:focus::placeholder, input[type="date"]:focus::placeholder, input[type="datetime-local"]:focus::placeholder, input[type="month"]:focus::placeholder, input[type="week"]:focus::placeholder, input[type="time"]:focus::placeholder, input[type="password"]:focus::placeholder, input[type="search"]:focus::placeholder, input[list]:focus::placeholder, textarea:focus::placeholder, select:focus::placeholder {
  color: var(--c-dark1);
}

::placeholder {
  color: var(--c-white);
  font-weight: normal;
}

::-webkit-calendar-picker-indicator {
  filter: invert();
}

input[type="number"], input[list] {
  line-height: revert;
  padding-right: 0;
}

input[type="checkbox"], input[type="radio"] {
  outline-color: var(--c-outline);
}

input[type="radio"] {
  outline-offset: 0;
}

input[type="color"] {
  height: 1rem;
  float: left;
  display: block;
}

input[type="file"] {
  box-sizing: border-box;
  height: 1rem;
  line-height: .8rem;
  display: block;
}

textarea {
  height: auto;
  padding-top: calc(.25rem - 2px);
  padding-bottom: calc(.25rem - 2px);
  line-height: 1rem;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: -1px;
}

td, th {
  border: 1px solid var(--c-border, #ddd);
  text-align: left;
  padding: calc(.25rem - 1px) .25rem .25rem;
}

th {
  background-color: var(--c-light1, #eee);
  font-weight: bold;
}

.dark-mode th {
  background-color: var(--c-dark1, #222);
}

.light-mode th {
  background-color: var(--c-light1, #eee);
}

caption {
  font-size: .9em;
  font-style: italic;
}

html {
  font-size: 32px;
}

body {
  font-size: 16px;
  line-height: 1rem;
}

.rhythm {
  --size: 1rem;
  --width: 1px;
  --color: #00000026;
  --color2: #0000000f;
  background-size: var(--size) var(--size), var(--size) var(--size);
  background-image: linear-gradient( to right, var(--color) 0%, var(--color) var(--width), transparent var(--width), transparent 50%, var(--color2) 50%, var(--color2) calc(50% + var(--width) ), transparent calc(50% + var(--width) ), transparent 100% ), linear-gradient( to bottom, var(--color) 0%, var(--color) var(--width), transparent var(--width), transparent 50%, var(--color2) 50%, var(--color2) calc(50% + var(--width) ), transparent calc(50% + var(--width) ), transparent 100% );
  will-change: background;
  transition: background .3s;
}

.rhythm.dark-mode, .dark-mode .rhythm {
  --color: #ffffff26;
  --color2: #ffffff0f;
}

.rhythm.light-mode, .light-mode .rhythm {
  --color: #00000026;
  --color2: #0000000f;
}

.btn, .btn--alt, button, [type="button"], [type="reset"], [type="submit"] {
  overflow-clip-margin: var(--border-width);
  text-transform: uppercase;
  font-family: var(--font-display);
  position: relative;
  overflow: clip;
}

.btn:before, .btn:after, .btn--alt:before, .btn--alt:after, button:before, button:after, [type="button"]:before, [type="button"]:after, [type="reset"]:before, [type="reset"]:after, [type="submit"]:before, [type="submit"]:after {
  content: "";
  box-sizing: border-box;
  bottom: calc(0px - var(--border-width) );
  left: calc(0px - var(--border-width) );
  pointer-events: none;
  width: 0;
  height: 0;
  z-index: 1;
  visibility: hidden;
  will-change: border, visibility, width, height;
  transition-property: border, visibility, width, height;
  transition-duration: var(--duration);
  border: 0 solid #0000;
  position: absolute;
}

.btn:before, .btn--alt:before, button:before, [type="button"]:before, [type="reset"]:before, [type="submit"]:before {
  border: 0 solid var(--hover-color);
  border-top-width: var(--border-width);
  border-left-width: var(--border-width);
  transition-delay: 0s, var(--duration), 0s, var(--duration);
}

.btn:after, .btn--alt:after, button:after, [type="button"]:after, [type="reset"]:after, [type="submit"]:after {
  border: 0 solid var(--hover-color);
  border-bottom-width: var(--border-width);
  border-right-width: var(--border-width);
  transition-delay: 0s, var(--duration), var(--duration), 0s;
}

.btn:focus, .btn--alt:focus, button:focus, [type="button"]:focus, [type="reset"]:focus, [type="submit"]:focus {
  outline-offset: 1px;
  outline-width: 2px;
}

.btn:hover, .btn:focus, .btn--alt:hover, .btn--alt:focus, button:hover, button:focus, [type="button"]:hover, [type="button"]:focus, [type="reset"]:hover, [type="reset"]:focus, [type="submit"]:hover, [type="submit"]:focus {
  color: var(--hover-color);
}

.btn:hover:before, .btn:hover:after, .btn:focus:before, .btn:focus:after, .btn--alt:hover:before, .btn--alt:hover:after, .btn--alt:focus:before, .btn--alt:focus:after, button:hover:before, button:hover:after, button:focus:before, button:focus:after, [type="button"]:hover:before, [type="button"]:hover:after, [type="button"]:focus:before, [type="button"]:focus:after, [type="reset"]:hover:before, [type="reset"]:hover:after, [type="reset"]:focus:before, [type="reset"]:focus:after, [type="submit"]:hover:before, [type="submit"]:hover:after, [type="submit"]:focus:before, [type="submit"]:focus:after {
  height: calc(100% + (var(--border-width) * 2) );
  width: calc(100% + (var(--border-width) * 2) );
  visibility: visible;
}

.btn:hover:before, .btn:focus:before, .btn--alt:hover:before, .btn--alt:focus:before, button:hover:before, button:focus:before, [type="button"]:hover:before, [type="button"]:focus:before, [type="reset"]:hover:before, [type="reset"]:focus:before, [type="submit"]:hover:before, [type="submit"]:focus:before {
  transition-delay: 0s, 0s, var(--duration), 0s;
}

.btn:hover:after, .btn:focus:after, .btn--alt:hover:after, .btn--alt:focus:after, button:hover:after, button:focus:after, [type="button"]:hover:after, [type="button"]:focus:after, [type="reset"]:hover:after, [type="reset"]:focus:after, [type="submit"]:hover:after, [type="submit"]:focus:after {
  transition-delay: 0s, 0s, 0s, var(--duration);
}

.btn:hover svg, .btn:focus svg, .btn--alt:hover svg, .btn--alt:focus svg, button:hover svg, button:focus svg, [type="button"]:hover svg, [type="button"]:focus svg, [type="reset"]:hover svg, [type="reset"]:focus svg, [type="submit"]:hover svg, [type="submit"]:focus svg {
  opacity: 1;
  transform: scale(1.2)rotate(10deg);
}

.btn:active, .btn--alt:active, button:active, [type="button"]:active, [type="reset"]:active, [type="submit"]:active {
  transform: translateY(1px);
}

.btn svg, .btn--alt svg, button svg, [type="button"] svg, [type="reset"] svg, [type="submit"] svg {
  height: .5rem;
  width: .5rem;
  fill: var(--color);
  opacity: .5;
  transition-property: fill, transform, opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, 0, 2);
  position: relative;
  top: .05rem;
}

.btn:hover svg, .btn:focus svg, .btn--alt:hover svg, .btn--alt:focus svg, button:hover svg, button:focus svg, [type="button"]:hover svg, [type="button"]:focus svg, [type="reset"]:hover svg, [type="reset"]:focus svg, [type="submit"]:hover svg, [type="submit"]:focus svg {
  fill: var(--hover-color);
}

.btn--cta, .dark-mode .btn--cta, .light-mode .btn--cta {
  --hover-color: var(--c-lodge-purple, purple);
}

.btn--go, .dark-mode .btn--go, .light-mode .btn--go {
  --hover-color: var(--c-success, green);
}

.btn--info, .dark-mode .btn--info, .light-mode .btn--info {
  --hover-color: var(--c-info, blue);
}

.btn--large {
  height: 1.5rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: 1.3em;
  line-height: 1.5rem;
}

.btn--large svg {
  height: .6rem;
  width: .6rem;
  top: .08rem;
}

.btn--alt {
  --border-radius: .2rem;
  --hover-color: var(--c-lodge-purple);
  height: 2rem;
  width: 2rem;
  border-radius: var(--border-radius);
  border-width: 2px;
  justify-content: center;
  align-items: center;
  margin-left: .25rem;
  margin-right: .25rem;
  padding: 0;
  display: inline-flex;
  transform: rotate(45deg);
}

.btn--alt:before, .btn--alt:after {
  display: none;
}

.btn--alt:hover, .btn--alt:focus {
  background-color: var(--hover-color);
  border-color: var(--hover-color);
}

.btn--alt:hover svg, .btn--alt:focus svg {
  transform: rotate(-50deg)scale(1.2);
}

.btn--alt svg {
  height: .95rem;
  width: .95rem;
  fill: var(--color);
  opacity: .8;
  transform: rotate(-35deg);
}

.btn--alt svg:first-of-type {
  margin-right: 0;
}

.btn--alt svg:last-of-type {
  margin-left: 0;
}

.btn--alt:hover svg, .btn--alt:hover path, .btn--alt:focus svg, .btn--alt:focus path {
  fill: var(--c-white, #fff);
}

.btn--instagram:hover, .btn--instagram:focus {
  --hover-color: var(--c-instagram);
}

.btn--facebook:hover, .btn--facebook:focus {
  background-color: var(--c-facebook);
  border-color: var(--c-facebook);
}

.btn--pinterest:hover, .btn--pinterest:focus {
  background-color: var(--c-pinterest);
  border-color: var(--c-pinterest);
}

.btn--borderless {
  border: 0 !important;
}

.btn--borderless:before, .btn--borderless:after {
  display: none;
}

.btn-group .btn {
  width: 100%;
  text-align: center;
}

@media (min-width: 640px) {
  .btn-group:not(.at800) {
    display: flex;
  }

  .btn-group:not(.at800) .btn {
    width: auto;
  }

  .btn-group:not(.at800) .btn + .btn {
    margin-left: calc(0px - var(--border-width) );
  }
}

@media (min-width: 800px) {
  .btn-group.at800 {
    width: auto;
    text-align: initial;
    display: flex;
  }

  .btn-group.at800 .btn {
    width: auto;
  }

  .btn-group.at800 .btn + .btn {
    margin-left: calc(0px - var(--border-width) );
  }
}

.card {
  --theme-color: var(--c-lodge-black, #bbb);
  --bg-color: #ffffffe6;
  --border-color: #1a1a1a1a;
  --border-size: 1px;
  box-shadow: var(--shdw-2);
  background-color: var(--bg-color);
  backdrop-filter: blur(.125rem);
  color: var(--c-text-dark, #0f0f0f);
  border: var(--border-size) solid var(--border-color);
  padding: calc(1rem - var(--border-size) ) 1rem;
  width: 100%;
  overflow: clip;
}

.card--info {
  --theme-color: var(--c-info);
}

.card--warning {
  --theme-color: var(--c-warning);
}

.card--success {
  --theme-color: var(--c-success);
}

.card__title {
  max-width: none;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  font-weight: normal;
  display: flex;
}

.card__title svg {
  height: 60%;
  width: 60%;
  fill: var(--c-white, #fff);
}

.card__title .card__icon {
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--theme-color, #bbb);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: .25rem;
  display: inline-flex;
}

.card__content {
  margin-top: .5rem;
}

.card--has-heading {
  border: 0;
  padding: 0;
}

.card--has-heading .card__title {
  height: calc(2rem - var(--border-size) );
  background-color: var(--theme-color, #333);
  color: var(--c-white, #fff);
  padding: 0 1rem;
  font-size: 1.5em;
}

.card--has-heading .card__content {
  border: var(--border-size) solid var(--border-color);
  border-top: 0;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

ldg-copy-card {
  height: 4rem;
  background-color: var(--c-white, #fff);
  color: var(--c-text-dark, #0f0f0f);
  box-shadow: var(--shdw-1);
  cursor: pointer;
  border: 1px solid #0000001a;
  border-radius: 3px;
  flex-direction: column;
  flex-basis: 4rem;
  display: flex;
  overflow: clip;
}

.copy-card__content {
  border-bottom: 1px solid var(--c-border, #ccc);
  background-color: var(--c-light1);
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: flex;
  position: relative;
}

.copy-card__content svg {
  height: 1rem;
  width: 1rem;
}

.copy-card__label {
  height: 1rem;
  font-family: var(--font-mono, monospace);
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .8em;
  line-height: 1;
  display: flex;
}

.copy-card__copy {
  width: 100%;
  height: 100%;
  color: var(--c-white, #fff);
  text-transform: uppercase;
  font-family: var(--font-mono, monospace);
  opacity: 0;
  will-change: opacity;
  background-color: #1a1a1a80;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: opacity .3s;
  display: flex;
  position: absolute;
}

.copy-card:hover .copy-card__copy, .copy-card:focus .copy-card__copy {
  opacity: 1;
}

.form__message {
  height: 0;
  color: var(--c-error);
  will-change: height;
  flex-direction: column;
  justify-content: flex-end;
  font-weight: bold;
  line-height: 1.3;
  transition: height .3s cubic-bezier(.4, 0, 0, 1.3);
  display: flex;
  position: relative;
  overflow: hidden;
}

.form.--dirty .form__message {
  height: 1rem;
}

.form.--valid .form__message {
  height: 0;
}

.form__submit {
  transition: width .3s cubic-bezier(.4, 0, 0, 1.3);
  display: flex;
}

.--submitting .form__submit {
  color: var(--hover-color);
  outline: 2px solid var(--c-lodge-pink);
  outline-offset: 1px;
}

.--submitting .form__submit:before, .--submitting .form__submit:after {
  height: calc(100% + (var(--border-width) * 2) );
  width: calc(100% + (var(--border-width) * 2) );
  visibility: visible;
}

.--submitting .form__submit:before {
  transition-delay: 0s, 0s, var(--duration), 0s;
}

.--submitting .form__submit:after {
  transition-delay: 0s, 0s, 0s, var(--duration);
}

.form__submitting {
  width: 0;
  opacity: 0;
  visibility: hidden;
  will-change: width, opacity, visibility;
  transition: width .3s cubic-bezier(.4, 0, 0, 1.3), opacity .3s, visibility .3s;
  overflow: hidden;
}

.form__submitting svg {
  --color: var(--c-lodge-pink);
  opacity: 1;
  animation-name: heartbeat;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.--submitting .form__submitting {
  width: 100%;
  opacity: 1;
  visibility: visible;
}

@keyframes heartbeat {
  from {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(1.4);
  }

  87.5% {
    transform: scale(1.2);
  }

  to {
    transform: scale(1.4);
  }
}

.fld {
  position: relative;
}

.fld ::placeholder {
  color: #0000 !important;
}

.fld--honey {
  display: none;
}

.fld__input {
  height: 2rem;
  will-change: opacity;
  padding-top: .5rem;
  padding-right: 1.25rem;
  font-size: 1.3em;
  transition: opacity .4s;
  position: relative;
}

.fld__input:focus {
  z-index: 1;
}

.fld__input:invalid:not(:focus):not(:placeholder-shown) {
  color: var(--c-error);
}

.fld + .fld .fld__input {
  border-top-width: 0;
}

textarea.fld__input {
  height: 6rem;
  resize: vertical;
  padding-top: .75rem;
}

.fld__label {
  z-index: 2;
  cursor: text;
  text-transform: uppercase;
  font-size: 1.2em;
  font-family: var(--font-display);
  white-space: nowrap;
  will-change: top, font-size, opacity;
  font-weight: 400;
  line-height: 2rem;
  transition: top .2s cubic-bezier(.4, 0, 0, 1.4) .1s, font-size .2s cubic-bezier(.4, 0, 0, 1.4) .1s, opacity .2s .1s;
  position: absolute;
  top: .1rem;
  left: calc(.5rem + 1px);
}

.fld__input:focus + .fld__label, .fld__input:not(:placeholder-shown) + .fld__label, .fld:focus-within .fld__label {
  color: var(--c-light2);
  font-size: .8em;
  top: -.5rem;
}

.fld__input:focus + .fld__label, .fld:focus-within .fld__label {
  color: var(--c-dark5);
}

.fld__message {
  height: 1rem;
  max-width: 90%;
  z-index: 2;
  background-color: var(--c-info);
  color: var(--c-white);
  opacity: 0;
  pointer-events: none;
  will-change: opacity, background-color;
  border-radius: 2px;
  align-items: center;
  margin-left: -3px;
  font-size: .9em;
  line-height: 1;
  transition: opacity .3s, background-color .3s;
  display: flex;
  position: absolute;
  bottom: 100%;
  left: -.125rem;
  box-shadow: 0 .1rem .2rem #0006;
}

.fld__message--tall {
  height: 1.5rem;
}

.fld__message > div {
  padding-left: .25rem;
  padding-right: .25rem;
}

.fld__message:after {
  content: "";
  z-index: 2;
  width: 0;
  height: 0;
  border-left: .3rem solid #0000;
  border-right: .3rem solid #0000;
  border-top: .25rem solid var(--c-info);
  transition: border-color .3s;
  display: block;
  position: absolute;
  top: 100%;
  left: .55rem;
}

.--dirty .fld__input:invalid ~ .fld__message, .fld__input.--dirty:invalid ~ .fld__message {
  background-color: var(--c-error);
}

.--dirty .fld__input:invalid ~ .fld__message:after, .fld__input.--dirty:invalid ~ .fld__message:after {
  border-top-color: var(--c-error);
}

.fld__input:focus:invalid ~ .fld__message, .fld__input:focus:invalid ~ .fld__message--tall {
  opacity: 1;
}

.fld__valid-icon, .fld__invalid-icon {
  z-index: 2;
  height: .8rem;
  width: .8rem;
  fill: currentColor;
  filter: invert(.2);
  opacity: 0;
  will-change: transform, filter, opacity;
  transition-property: transform, filter, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, 0, 1.4);
  display: inline-block;
  position: absolute;
  top: .55rem;
  right: .25rem;
  transform: scale(0);
}

.fld__input:focus ~ .fld__valid-icon, .fld__input:focus ~ .fld__invalid-icon {
  filter: none;
}

.fld__valid-icon {
  color: var(--c-success);
}

.--dirty .fld__input:valid ~ .fld__valid-icon, .--dirty:valid ~ .fld__valid-icon {
  opacity: 1;
  transform: scale(1);
}

.fld__invalid-icon {
  color: var(--c-error);
}

.--dirty .fld__input:invalid ~ .fld__invalid-icon, .--dirty:invalid ~ .fld__invalid-icon {
  opacity: 1;
  transform: scale(1);
}

.fld-row {
  margin-top: 1rem;
}

.fld-row:first-of-type {
  margin-top: 0;
}

.fld-inline {
  margin-right: .5rem;
}

.fld-inline:last-of-type {
  margin-right: 0;
}

.fld-msg {
  box-sizing: border-box;
  height: 1rem;
  padding-top: .125rem;
  display: block;
}

.fld__set {
  border: 0;
  padding: 0;
}

.fld__set[disabled] .fld__label, .fld__set[disabled] .fld__input {
  opacity: .7;
}

.--submitting .btn[type="submit"] {
  pointer-events: none;
  cursor: not-allowed;
}

.toggle {
  --height: .8rem;
  --switch-size: .6rem;
  --switch-left: .1rem;
  --duration: .2s;
  --bg-color: #ccc;
  --bg-color-active: var(--c-info, blue);
  --switch-bg-color: #fff;
  --switch-on-color: var(--c-white, #fff);
  --switch-off-color: var(--c-black, #000);
  height: var(--height);
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.toggle + .toggle {
  margin-left: .25rem;
}

.toggle__label {
  margin-right: .25rem;
}

.toggle__switch ~ .toggle__label {
  margin-left: .25rem;
  margin-right: 0;
}

.toggle__on, .toggle__off {
  text-transform: lowercase;
  justify-content: center;
  align-items: center;
  margin: 0 .25rem;
  font-size: .8em;
  font-weight: 400;
  transition-property: opacity;
  display: inline-flex;
}

.toggle__input:checked + .toggle__switch .toggle__on, .toggle__input:checked + .toggle__switch .toggle__off {
  color: var(--c-white, #fff);
}

.toggle__on svg, .toggle__on path, .toggle__off svg, .toggle__off path {
  fill: currentColor;
  stroke: currentColor !important;
}

.toggle__on {
  color: var(--switch-on-color);
  opacity: 0;
  transition-duration: calc(var(--duration) * 2);
  order: 1;
  margin-right: .1rem;
}

.toggle__on svg {
  position: relative;
  left: .05rem;
}

.toggle__input:checked + .toggle__switch .toggle__on {
  opacity: 1;
  transition-duration: 0s;
}

.toggle__off {
  color: var(--switch-off-color);
  opacity: 1;
  order: 2;
  margin-left: .1rem;
  transition-duration: 0s;
}

.toggle__off svg {
  position: relative;
  left: -.05rem;
}

.toggle__input:checked + .toggle__switch .toggle__off {
  opacity: 0;
  transition-duration: calc(var(--duration) * 2);
}

.toggle__input {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.toggle__switch {
  height: 100%;
  min-width: calc(var(--height)  + var(--switch-size) );
  background-color: var(--bg-color);
  border-radius: var(--height);
  will-change: background-color;
  transition-property: background-color;
  transition-duration: var(--duration);
  align-items: center;
  display: inline-flex;
  position: relative;
}

.toggle__switch:before {
  content: "";
  left: var(--switch-left);
  height: var(--switch-size);
  width: var(--switch-size);
  background-color: var(--switch-bg-color);
  z-index: 1;
  will-change: left;
  transition-property: left;
  transition-duration: var(--duration);
  border-radius: 50%;
  transition-timing-function: cubic-bezier(.4, 0, 0, 1.4);
  display: inline-block;
  position: absolute;
}

.toggle__switch svg {
  width: 1.3em;
  height: 1.3em;
}

.toggle__input:focus + .toggle__switch {
  box-shadow: 0 0 0 2px var(--c-lodge-pink, pink);
}

.toggle__input:checked + .toggle__switch {
  background-color: var(--bg-color-active);
}

.toggle__input:checked + .toggle__switch:before {
  left: calc(100% - var(--switch-size)  - var(--switch-left) );
}

.heading {
  --border-width: 4px;
  text-align: center;
}

.heading > :first-child {
  border-bottom: var(--border-width) double currentColor;
  margin-bottom: calc(0px - var(--border-width) );
  display: inline-block;
}

.media {
  border: .25rem solid var(--c-white, #fff);
  box-shadow: 0 .25rem 1rem var(--c-black, #000);
  max-width: 100%;
}

.section {
  min-height: 100vh;
  border-top: .25rem solid var(--c-lodge-brown, brown);
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3rem;
  position: relative;
}

.section:first-of-type {
  min-height: calc(100vh - 5rem);
}

.section:after {
  content: "";
  pointer-events: none;
  background: #00000080 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAxhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDM0MiwgMjAxMC8wMS8xMC0xODowNjo0MyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoxNUIyMzQxMkVFMDkxMUUzOTZENEZEQTQ1RjQ0OEU5MyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoxNUIyMzQxM0VFMDkxMUUzOTZENEZEQTQ1RjQ0OEU5MyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjE1QjIzNDEwRUUwOTExRTM5NkQ0RkRBNDVGNDQ4RTkzIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjE1QjIzNDExRUUwOTExRTM5NkQ0RkRBNDVGNDQ4RTkzIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+IlX97wAAABhJREFUeNpi/P//PwMcQDkNIJoRWQYgwAAQYgx3/SJKogAAAABJRU5ErkJggg==");
  display: block;
  position: absolute;
  inset: 0;
}

.section__content {
  width: 90vw;
  max-width: 40rem;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  display: flex;
  position: relative;
}

.section:first-child > .section__content {
  padding-top: 1rem;
}

@media (min-width: 24rem) {
  .section__content {
    width: 94vw;
  }
}

.section__title {
  margin-bottom: 1rem;
  font-size: 2em;
  font-weight: normal;
  line-height: 1.5rem;
}

.section__subtitle {
  text-transform: none;
  justify-content: center;
  margin-top: .5rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.6em;
  display: flex;
}

.section__subtitle .icon {
  height: 1.5em;
  min-width: 1.5em;
  fill: var(--c-white);
  margin-right: .2em;
  display: inline-block;
  position: relative;
  top: -.2em;
}

.section__intro {
  font-size: 1.3em;
}

.footer {
  background-color: var(--c-white, #fff);
  color: var(--c-text-dark, #0f0f0f);
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.footer__logo {
  height: 3rem;
}

.footer__logo svg {
  width: 100%;
  height: 100%;
}

.footer__text {
  height: 5rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  line-height: 1.3;
  display: flex;
}

.footer__text > div + div {
  margin-top: .5rem;
}

.footer__text a:before {
  content: "";
  display: block;
}

@media (min-width: 640px) {
  .footer__content {
    align-items: center;
    gap: 1rem;
    display: flex;
  }

  .footer__text {
    height: 3rem;
    text-align: left;
    padding-top: .5rem;
  }

  .footer__text > div + div {
    margin-top: 0;
  }

  .footer__text a:before {
    display: none;
  }
}

.d-b {
  display: block;
}

.d-ib {
  display: inline-block;
}

.d-i {
  display: inline;
}

.d-f {
  display: flex;
}

.d-fw {
  flex-wrap: wrap;
  display: flex;
}

.d-if {
  display: inline-flex;
}

.d-ifw {
  flex-wrap: wrap;
  display: inline-flex;
}

.d-g {
  display: grid;
}

.d-ig {
  display: inline-grid;
}

.d-c {
  display: contents;
}

.d-li {
  display: list-item;
}

.d-no {
  display: none;
}

.d-clr {
  clear: both;
}

.d-full {
  width: 100%;
}

.flx-center {
  justify-content: center;
}

.flx-middle {
  align-items: center;
}

.flx-grow {
  flex-grow: 1;
}

.flx-wrap {
  flex-wrap: wrap;
}

.flx-sb {
  justify-content: space-between;
}

.flx-col {
  flex-direction: column;
}

.mw-0 {
  max-width: none;
}

.mw-20 {
  max-width: 20rem;
}

.p-0 {
  padding: 0 !important;
}

.py-0 {
  padding-block: 0 !important;
}

.py-0-5 {
  padding-block: .5rem !important;
}

.py-1 {
  padding-block: 1rem !important;
}

.py-2 {
  padding-block: 2rem !important;
}

.py-3 {
  padding-block: 3rem !important;
}

.py-4 {
  padding-block: 4rem !important;
}

.px-0 {
  padding-inline: 0 !important;
}

.px-0-5 {
  padding-inline: .5rem !important;
}

.px-1 {
  padding-inline: 1rem !important;
}

.px-2 {
  padding-inline: 2rem !important;
}

.px-3 {
  padding-inline: 3rem !important;
}

.px-4 {
  padding-inline: 4rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-0-5 {
  padding-top: .5rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-0-5 {
  padding-bottom: .5rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-0-5 {
  padding-left: .5rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-0-5 {
  padding-right: .5rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.m-0 {
  margin: 0 !important;
}

.my-0 {
  margin-block: 0 !important;
}

.my-0-5 {
  margin-block: .5rem !important;
}

.my-1 {
  margin-block: 1rem !important;
}

.my-2 {
  margin-block: 2rem !important;
}

.my-3 {
  margin-block: 3rem !important;
}

.my-4 {
  margin-block: 4rem !important;
}

.mx-0 {
  margin-inline: 0 !important;
}

.mx-0-5 {
  margin-inline: .5rem !important;
}

.mx-1 {
  margin-inline: 1rem !important;
}

.mx-2 {
  margin-inline: 2rem !important;
}

.mx-3 {
  margin-inline: 3rem !important;
}

.mx-4 {
  margin-inline: 4rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-0-5 {
  margin-top: .5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-0-5 {
  margin-bottom: .5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-0-5 {
  margin-left: .5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-0-5 {
  margin-right: .5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.gap-0-5 {
  gap: .5rem !important;
}

.gap-1 {
  gap: 1rem !important;
}

.gap-2 {
  gap: 2rem !important;
}

.gap-3 {
  gap: 3rem !important;
}

.gap-4 {
  gap: 4rem !important;
}

.txt-lg {
  font-size: 1.25em;
}

.txt-center {
  text-align: center;
}

.txt-shadow {
  text-shadow: 0 0 3px var(--c-black, #000);
}

.txt-uc {
  text-transform: uppercase;
}

.txt-black {
  color: var(--c-lodge-black, #222);
}

/*# sourceMappingURL=index.5558d579.css.map */
